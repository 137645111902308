import { Row, Col, Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";
import Sidebar from "../components/Sidebar";
import SettingSidebar from "../pages/main/settings/settingsMain/components/SettingSidebar";

const SettingLayout = () => {
  return (
    <Container fluid className="main-bg">
      <Row className="rounded-4 position-fixed z-3 bg-white m-0 p-0 d-lg-none d-xs-block">
        <MobileHeader />
      </Row>
      <Row className="p-1" style={{ minHeight: "100vh" }}>
        <Col
          md={2}
          className="rounded-4 bg-white-80 me-2 d-none d-lg-block"
          style={{
            position: "fixed",
            height: "100vh",
            overflowY: "auto",
            zIndex: 1,
          }}
        >
          <Sidebar />
        </Col>

        <Col
          md={3}
          lg={2}
          style={{
            marginLeft: "17%", // Adjust based on sidebar width (e.g., 2/12 for md={2})
          }}
          className="rounded-4 bg-white-80 me-2"
        >
          <h5 className="text-black fs-6 p-2">Settings</h5>
          <SettingSidebar />
        </Col>

        <Col className="rounded-4 bg-white-80 overflow-y-scroll">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default SettingLayout;
