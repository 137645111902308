import { Row, Col, Container } from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import { Outlet } from "react-router-dom";
import MobileHeader from "../components/MobileHeader";

const MainLayout = () => {
  return (
    <Container fluid className="main-bg">
      {/* Mobile Header */}
      <Row className="rounded-4 position-fixed z-3 bg-white m-0 p-0 d-lg-none d-xs-block">
        <MobileHeader />
      </Row>
      <Row className="p-1" style={{ minHeight: "100vh" }}>
        {/* Sidebar */}
        <Col
          md={2}
          className="rounded-4 bg-white-80 d-none d-lg-block"
          style={{
            position: "fixed",
            height: "100vh",
            overflowY: "auto",
            zIndex: 1,
          }}
        >
          <Sidebar />
        </Col>
        {/* Main Content */}
        <Col
          className="rounded-4 bg-white-80 overflow-y-scroll"
          style={{
            marginLeft: "17%", // Adjust based on sidebar width (e.g., 2/12 for md={2})
          }}
        >
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default MainLayout;
